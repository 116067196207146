.floating-dock{
    position: fixed;
    right: 2%;
    bottom: 2%;
    z-index: 1000;
    width: 125px;
    height: 60px;
    background-color: #fff;
    border-radius: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.floating-dock:hover{
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
}

