/* .watermark{
    position:absolute;
    bottom: 0;
    left: 0;
    width: auto;
    height: 100%;
    scale: 0.3;
     right: 100%; 
    opacity: 0.2;
    z-index: 1000;
    object-fit: contain;
} */

.watermark {
    position: absolute;
    bottom: 150px;
    right: -200px;
    z-index: 1;
    /* scale: 2.3; */
    opacity: 0.2; /* Adjust the opacity as needed */
  }
  
  .watermark img {
    width:400px; /* Adjust the size of the watermark image */
  }
  
  .watermark2 {
    position: absolute;
    bottom: 150px;
    left: -200px;
    z-index: 1;
    /* scale: 2.3; */
    opacity: 0.2; /* Adjust the opacity as needed */
  }
  
  .watermark2 img {
    width:400px; /* Adjust the size of the watermark image */
  }
  