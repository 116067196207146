@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@700&display=swap');

/* Existing quote styles */
.quote {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
    font-style: italic;
    position: relative;
    margin: 20px 0;
    padding: 20px;
    border-left: 5px solid #ccc;
}

.quote::before {
    content: open-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-right: 10px;
    vertical-align: -0.4em;
}

.quote::after {
    content: close-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-left: 10px;
    vertical-align: -0.4em;
}

/* New heading styles */
.title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2em;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;
}


.title::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background: #2D2F7C; /* Tomato color */
    margin-top: 10px;
}
